import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import { Link } from "gatsby"
import { BiArrowBack } from "react-icons/bi"
const Page_Builder = () => {
  return (
    <Layout>
      <Seo
        title="Amazon Product Page Builder"
        description="Best SEO for Amazon Products sourcing many keywords"
      />
      <Container fluid className="px-0">
        <div className="text-center " style={{ position: "relative" }}>
          <Styledh1 className="display-4">
            Amazon Product Page Builder{" "}
          </Styledh1>
          <StaticImage
            className="mt-2"
            src="../../assets/images/featuresbg.jpg"
            alt="Amazon FBA App Features"
            placeholder="tracedSVG"
            layout="fullWidth"
            aspectRatio={6 / 1}
            style={{ zIndex: "-1" }}
          ></StaticImage>
        </div>
        <Container className="p-5">
          <Row className="mx-auto">
            <Col>
              <Row className="text-center">
                <Col sm={12} className="pb-5">
                  <StaticImage
                    src="../../assets/images/azszero-pagebuilder.jpg"
                    alt="Amazon ASIN page builder"
                    placeholder="tracedSVG"
                    layout="constrained"
                    aspectRatio={1 / 1}
                    height="600"
                  ></StaticImage>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={8} className="pb-5">
                  <ul>
                    <li>
                      Compliance with Amazon Product Page rules and guidelines
                    </li>
                    <li>
                      It guides you to write a perfect SEO-Ready title,
                      features, and description.
                    </li>
                    <li>
                      Source as many keywords as you want from Amazon Live
                      Database, also the possibility to source from 3P keywords
                      providers.
                    </li>
                    <li>
                      It alerts you of short or lengthy titles, It also gives
                      you stats on your features bullet points.
                    </li>
                    <li>
                      It alerts you when you repeat a word that you previously
                      wrote in the title, features, or description so that you
                      maximize the number of unique words on your product page.
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link to="/Amazon_FBA_Seller_App_Features">
                <BiArrowBack />
                &nbsp;&nbsp;Back to Features Page
              </Link>{" "}
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}
const Styledh1 = styled.h1`
  padding: 30px;
  color: slategray;
  margin-bottom: -50px;
  z-index: 10;
  font-weight: 700;
`
export default Page_Builder
